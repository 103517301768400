import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  // createWebHistory 路由模式路径不带#号(生产环境下不能直接访问项目，需要 nginx 转发)
  // createWebHashHistory 路由模式路径带#号
  // history: createWebHashHistory(),
  mode: 'hash',
  routes: [
    // {
    //   path: '/',
    //   redirect: '/home'
    // },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home'),
      redirect:'index',
      children:[
        {
          path: '/index',
          name: 'index',
          component: () => import('@/components/index/Index.vue'),
        },
        {
          path: '/qualityProjects',
          name: 'qualityProjects',
          component: () => import('@/components/qualityProjects/Index.vue'),
        },
        {
          path: '/customized',
          name: 'customized',
          component: () => import('@/components/customized/Index.vue'),
        },
        {
          path: '/learning',
          name: 'learning',
          component: () => import('@/components/learning/Index.vue'),
        },
        {
          path: '/getIntegral',
          name: 'getIntegral',
          component: () => import('@/components/getIntegral/Index.vue'),
        }
      ]
    },
    {
      path: '/projectDetail/:id',
      name: 'projectDetail',
      component: () => import('@/views/ProjectDetail')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login')
    },
    {
      path: '/adminIndex',
      name: 'adminIndex',
      component: () => import('@/views/admin/Index'),
      meta:{
        requireAuth: true
      }
    },
    {
      path: '/adminReview',
      name: 'adminReview',
      component: () => import('@/views/admin/Review'),
      meta:{
        requireAuth: true
      }
    },
    {
      path: '/adminClass',
      name: 'adminClass',
      component: () => import('@/views/admin/Class'),
      meta:{
        requireAuth: true
      }
    },
    {
      path: '/adminProject',
      name: 'adminProject',
      component: () => import('@/views/admin/Project'),
      meta:{
        requireAuth: true
      }
    },
    {
      path: '/adminBnet',
      name: 'adminBnet',
      component: () => import('@/views/admin/Bnet'),
      meta:{
        requireAuth: true
      }
    }
  ]
})

// 解决 vue 中路由跳转时，总是从新页面中间开始显示
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
})

export default router